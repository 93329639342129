import React, {useState, useEffect} from "react"
import Layout from "../components/layout"
import Curso from "../components/curso"
import Tree from "../images/cursos/Tree.webp"
import OldMan from "../Assets/old_man.webp"

const Liquida = () => {
    const [hasMounted, setHasMounted] = useState(false) 

    useEffect(() => {
        setHasMounted(true)
    }, [])

    return hasMounted ? (
        <Layout>
            <Curso 
                title='La vida líquida'
                phrase='La pérdida del sentido en sociedades modernas de consumo'
                time='6 Horas'
                class='4 Sesiones'
                students='3 - 6 Estudiantes'
                firstImg={Tree}
                secondImg={OldMan}
                description='En este curso revisaremos las ideas del sociólogo Zygmunt Bauman sobre la experiencia de “liquidez” que resulta de vivir en sociedades donde todo cambia a velocidades vertiginosas. Nos enfocaremos en los efectos que esta vida líquida tiene en las relaciones interpersonales, la psique del individuo y la toma de decisiones.'
                list={['Introducción a la vida y obra de Z. Bauman y su relación con conceptos existencialistas.', 'La vida líquida.', 'La cultura líquida: fama vs. arte.', 'El individuo y sus identidades.']}
                duracion='4 sesiones de entre 80 y 90 min.'
                cupo='mínimo 3 personas, máximo 6 personas.'
                precio='$1,500 MXN.'
                top='10%'
                imgWidth='16%'
                right='5%'
                topMob='15%'
                rightMob='7%'
                imgWidthMob='23%'
            />
        </Layout>
    ) : null
}

export default Liquida